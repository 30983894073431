@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins" !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 85%;
  .h-screen {
    height: 117.5vh !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.editInp {
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius: 16px;
  border: 1px solid #e0e5f2;
}

.setButton {
  border-radius: 16px;
  transition-duration: 0.3s;
}

.hovButton:hover {
  background-color: rgb(255, 47, 96);
  opacity: 0.8;
}

.inpStyle {
  border: 0;
  background: transparent;
  outline: 2px solid #e2e8f0;
}

.productDetail {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ff1d52 #edeaeb;
}

.productDetail::-webkit-scrollbar {
  width: 4px;
}

.productDetail::-webkit-scrollbar-track {
  background-color: #00000070;
}

.productDetail::-webkit-scrollbar-thumb {
  background-color: #ff1d52;
}

.portals {
  background-color: #00000057;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 100;
  overflow-y: auto;
  padding-top: auto;
}

.portals-content {
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.custom-html-content p {
  margin-top: 10px;
  margin-bottom: 5px;
}

.swiper {
  width: 60%;
  height: auto;
  margin-left: 0;
}

.swiper,
.swiper-container {
  margin-left: 0 !important;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
  --swiper-navigation-color: #ff2f60;
}

.swiper-pagination-bullet {
  --swiper-pagination-color: #ff2f60;
}

.setBefore::before {
  content: "";
  position: absolute;
  border: 1px solid #000;
  border: 3px solid #cdcdcd;
  width: 82.8vw;
  height: 0;
  top: 42px;
  left: calc(50% - 83.5vw / 2);
  right: calc(50% - 100vw / 2);
}

.pick-up {
  background-color: #ff2f60;
}

.in-process {
  background-color: #cdcdcd;
}

.on-delivery {
  background-color: #cdcdcd;
}

.completed {
  background-color: #cdcdcd;
}

.dataTables_wrapper {
  margin-top: -40px;
  .row {
    &:nth-child(1) {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }

    div[data-test="datatable-search"] {
      justify-content: flex-start !important;
      margin: 10px 0;

      input {
        font-size: 14px;
        font-weight: 400;
        min-height: 30px;
        border: 1px solid #ced4da;
        padding: 5px 10px;
        border-radius: 5px;
        min-width: 300px;
      }
    }

    div[data-test="datatable-table"] {
      div[data-test="table"] {
        table {
          min-width: 100%;
          border-radius: 15px !important;
          text-transform: capitalize;

          thead {
            background-color: #ff1d52;
            color: #fff;

            tr {
              th {
                padding-right: 35px;
                white-space: nowrap;
              }

              th:first-child {
                border-left: 0;
                border-top: 0;
                border-top-left-radius: 15px !important;
              }

              th:last-child {
                border-right: 0;
                border-top: 0;
                border-top-right-radius: 15px !important;
              }
            }
          }

          tr {
            th,
            td {
              font-weight: 400 !important;
              border: 1px solid #c7dbff;
              padding: 10px;
              text-align: left;
            }
          }

          tbody {
            tr td {
              color: #495057;
              font-size: 14px;

              .text-white {
                color: #fff !important;
              }
            }
          }
        }

        table.dataTable thead > tr > th.sorting_asc,
        table.dataTable thead > tr > th.sorting_desc,
        table.dataTable thead > tr > th.sorting,
        table.dataTable thead > tr > td.sorting_asc,
        table.dataTable thead > tr > td.sorting_desc,
        table.dataTable thead > tr > td.sorting {
          padding-right: 30px;
        }

        table.dataTable thead > tr > th:active,
        table.dataTable thead > tr > td:active {
          outline: none;
        }

        table.dataTable thead .sorting,
        table.dataTable thead .sorting_asc,
        table.dataTable thead .sorting_desc,
        table.dataTable thead .sorting_asc_disabled,
        table.dataTable thead .sorting_desc_disabled {
          position: relative;
          cursor: pointer;
        }

        table.dataTable thead .sorting:before,
        table.dataTable thead .sorting:after,
        table.dataTable thead .sorting_asc:before,
        table.dataTable thead .sorting_asc:after,
        table.dataTable thead .sorting_desc:before,
        table.dataTable thead .sorting_desc:after,
        table.dataTable thead .sorting_asc_disabled:before,
        table.dataTable thead .sorting_asc_disabled:after,
        table.dataTable thead .sorting_desc_disabled:before,
        table.dataTable thead .sorting_desc_disabled:after {
          position: absolute;
          bottom: 0.9em;
          display: block;
          opacity: 0.3;
        }

        table.dataTable thead .sorting:before,
        table.dataTable thead .sorting_asc:before,
        table.dataTable thead .sorting_desc:before,
        table.dataTable thead .sorting_asc_disabled:before,
        table.dataTable thead .sorting_desc_disabled:before {
          right: 1em;
          font-family: "Font Awesome\ 5 Free", sans-serif;
          font-size: 1rem;
          font-weight: 900;
          content: "\f0de";
        }

        table.dataTable thead .sorting:after,
        table.dataTable thead .sorting_asc:after,
        table.dataTable thead .sorting_desc:after,
        table.dataTable thead .sorting_asc_disabled:after,
        table.dataTable thead .sorting_desc_disabled:after {
          right: 16px;
          font-family: "Font Awesome\ 5 Free", sans-serif;
          font-size: 1rem;
          font-weight: 900;
          content: "\f0dd";
        }

        table.dataTable thead .sorting_asc:before,
        table.dataTable thead .sorting_desc:after {
          opacity: 1;
        }

        table.dataTable thead .sorting_asc_disabled:before,
        table.dataTable thead .sorting_desc_disabled:after {
          opacity: 0;
        }
      }
    }

    &:nth-child(3) {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      div[data-test="datatable-pagination"] {
        .dataTables_paginate ul {
          display: flex;
          justify-content: flex-end;
          gap: 10px;

          .page-item {
            a {
              background-color: rgba(#ff1d52, 1);
              border-radius: 5px;
              height: 40px;
              min-width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 5px 10px;
              color: #fff;
              cursor: pointer;
              span.sr-only {
                display: none;
              }
            }

            &:not(:first-child, :last-child) {
              a {
                background-color: rgba(#ff1d52, 0.1);
                color: rgba(#ff1d52, 1);
                padding: 0px;
              }

              &.active a {
                border: 1.5px solid rgba(#ff1d52, 1);
              }
            }
            &.disabled {
              cursor: not-allowed;
              a {
                cursor: not-allowed;
                pointer-events: none;
                background-color: gray;
              }
            }
          }
        }
      }
    }
  }
}
