.multiSelectionBox {
  width: 100% !important;

  label {
    font-size: 14px !important;
    transform: translate(14px, 12px) scale(1);
  }

  .MuiInputLabel-shrink {
    transform: translate(14px, -9px) scale(0.75);
  }

  .renderBox {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    svg {
      font-size: 16px;
    }
  }

  .MuiOutlinedInput-root {
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #c4c4c4 !important;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 1px !important;
      border-color: #c4c4c4 !important;
    }
  }

  .Mui-focused {
    color: #ff1d52 !important;
  }

  .MuiInputBase-root {
    border-radius: 4px;
    min-height: 43px;
    background: #fff;

    input {
      font-weight: 400;
      font-size: 13px;
    }

    legend > span {
      font-size: 12px !important;
    }
  }

  .MuiSelect-outlined {
    padding: 0%;
  }

  .add-padding {
    padding: 16.5px 14px !important;
  }

  .MuiChip-root {
    background-color: #ff1d52;

    span,
    svg {
      color: white;
    }

    svg:hover {
      color: #e6e6e6;
    }
  }
}