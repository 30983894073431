.modjenLoader {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);

  img {
    width: 150px;
    object-fit: contain;
  }
}
